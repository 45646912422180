import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { heroicons } from '@assets/icons/heroicons';
import { format, parseISO } from 'date-fns';
import {
  ColumnConfig,
  DetailsRowConfig,
  DisplaybleDataRow,
  Pagination,
  PaginationConfig,
  Row,
  ServerConfig,
} from '../../models/server-pagination';
import { QueryParamsService } from '../../services/queryParams.service';
import { observeProperty } from '../../utils/observeProperty';

@Component({
  selector: 'tu-table-server',
  templateUrl: './table-server.component.html',
})
export class TableServerComponent implements OnInit {
  constructor(private queryParamsService: QueryParamsService) { }

  @Input() columnsConfig: ColumnConfig[] = [];

  @Input() data?: Row[] = [];
  @Input() idKey?: string;

  @Input('antiCache')
  public antiCache = false;

  @Input('hasCheckbox')
  public hasCheckbox = false;

  @Input('hasCheckboxForAll')
  public hasCheckboxForAll = true;

  private _filters: Record<string, string>;

  @Input() set filters(filters: Record<string, string>) {
    if (filters.resetPagination) {
      this.resetPagination();
      delete filters.resetPagination;
    }
    this._filters = filters;

    //

    if (this.displayableData) this.retrieveData();
  }
  get filters() {
    return this._filters;
  }

  @Input() rowLink?: <Row = unknown>(row: Row) => string;

  @Input() serverConfig: ServerConfig | false = false;
  @Input() fetchConfig: RequestInit = { method: 'GET' };

  public allSelected = false;
  public selectedRows: Row[] = [];

  @Input() paginationConfig: PaginationConfig = { limit: 10, offset: 0 };

  @Input() setQueryParams: boolean = true;

  public _pagination: Pagination = {
    sortableKeys: [],
    limit: 10,
    currentPage: 1,
    offset: 0,
    count: 0,
    pages: 10,
  };

  public get pagination() {
    return this._pagination;
  }
  public set pagination(config) {
    this._pagination = config;

    if (this.setQueryParams) {
      this.queryParamsService.paginationParams = { limit: config.limit, offset: config.offset };
    }
  }

  @Input() updateContent = true;

  public resetPagination() {
    if (this.setQueryParams) {
      this.queryParamsService.paginationParams = { offset: 0, limit: this.pagination.limit };
    }

    this.pagination.offset = 0;

    // Temporarly disabling that
    // this.retrieveData();
  }

  // The icon library: https://heroicons.com/
  public heroicons = heroicons;

  // The actual data that will be displayed in the table
  public displayableData: DisplaybleDataRow[];

  // The actualy columns that will be displayed in the table
  public get displayableColumns(): ColumnConfig[] {
    return this.columnsConfig.filter((column) => column.isDisplayed?.() ?? true);
  }

  // This controller is used to Abort data loading when we are changing
  // the page but didn't complete the current network request
  private abortController: AbortController;
  public isLoading = false;
  public isLoading$ = observeProperty(this, 'isLoading');

  /**
   * This method mostly just validate that all the critical configuration
   * is properly configured before proceeding to fetch the data from the server
   *
   * @param server {ServerConfig} - The server configuration
   */
  private validateServerConfiguration(server: ServerConfig) {
    try {
      new URL(server.url);
    } catch {
      throw new Error('INVALID_SERVER_URL');
    }

    const keys = ['url', 'sortableKeys', 'limit', 'data', 'offset', 'count', 'pages'];

    for (const key of keys) {
      if (server[key]) continue;
      throw new Error(`MISSING_MANDATORY_KEY:${key}`);
    }
  }

  /**
   * Given a server configuration, retrieve the data from that configuration
   * with the current parameters set in this.pagination.
   *
   * It aborts previous calls upon new requests if the previous one is unfinished.
   *
   * @param server {ServerConfig}
   */
  private async getDataFromServer(server: ServerConfig) {
    let data: Row[] = [];
    try {
      this.validateServerConfiguration(server);

      const url = new URL(server.url);

      const pagination = {
        offset: this.pagination.offset,
        limit: this.pagination.limit,
      };

      url.searchParams.set('pagination', JSON.stringify(pagination));

      if (this.filters) {
        Object.entries(this.filters).forEach(([key, value]) => {
          if (value === null) return;

          url.searchParams.set(key, value);
        });
      }

      if (this.pagination.sortBy && this.pagination.sortIn) {
        url.searchParams.set('sortIn', this.pagination.sortIn);
        url.searchParams.set('sortBy', this.pagination.sortBy);
      }

      if (this.antiCache) {
        // Ideally it would also set the following headers:
        // - Cache-Control: no-cache, no-store, must-revalidate
        // - Pragma: no-cache
        // - Expires: 0
        // But right this moment, it would be annoying with the current implementation
        url.searchParams.set('t', Date.now().toString());
      }

      if (this.abortController) this.abortController.abort();

      this.abortController = new AbortController();
      const { signal } = this.abortController;

      this.isLoading = true;

      const response = await fetch(url.toString(), { ...this.fetchConfig, signal });

      const sortableKeys = server.sortableKeys(response);
      const offset = server.offset(response);
      const limit = server.limit(response);
      const currentPage = offset / limit + 1;
      const count = server.count(response);
      const pages = Math.ceil(count / limit);
      const body = await response.json();
      data = server.data(body);

      this.pagination = {
        ...this.pagination,
        ...{ currentPage, sortableKeys, offset, limit, count, pages },
      };

      return data;
    } catch (e) {
      if (e.name === 'AbortError') return data;
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  @Output() onReady = new EventEmitter<boolean>();

  public async ngOnInit() {
    if (this.setQueryParams) {
      const params = this.queryParamsService.paginationParams;

      const limit = params.limit || this.paginationConfig.limit;
      const offset = params.offset || this.paginationConfig.offset;

      this.pagination = { ...this.pagination, limit, offset };
    } else {
      this.pagination = {
        ...this.pagination,
        limit: this.paginationConfig.limit,
        offset: this.paginationConfig.offset,
      };
    }

    this.onReady.emit(true);

    this.retrieveData();
  }

  /**
   * This just generate a bunch of fake empty rows that serves
   * in to iterrate on the markup and show a placeholder when data
   * is still loading in on first run.
   */
  public get fakeRows() {
    return new Array(this.pagination.limit).fill('');
  }

  /**
   * Fetch and format the rows
   */
  public async retrieveData() {
    if (this.displayableData && !this.updateContent) return;

    try {
      const data = this.serverConfig ? await this.getDataFromServer(this.serverConfig) : this.data;
      this.displayableData = data.map((row) => {
        const cells = this.displayableColumns.map((column) => {
          // TODO: Add try catch if we don't find the value
          // Given the following row: { user: { lastname: 'test' } }
          // and the following key: 'user.lastname'
          // we get: ['user', 'lastname'] and reduce it to row['user']['lastname']
          const rawValue = column.key?.split('.').reduce((value, path) => value[path], row) ?? '';
          const cell = column.modifier?.(rawValue, row) ?? rawValue;

          switch (column.type) {
            case 'date': {
              const value = rawValue
                ? format(parseISO(rawValue), column.config?.format ?? 'dd/MM/yyyy HH:mm')
                : '-';
              return { value, config: column?.config };
            }

            // TODO: Add a fallback when the currency isn't native. eg: bitcoin
            case 'price': {
              const locale = column?.config?.locale(row) ?? 'fr-FR';
              const currency = column?.config?.currency(row) ?? 'EUR';

              const value = parseFloat(cell).toLocaleString(locale, {
                style: 'currency',
                currency,
              });

              return { value, config: column?.config, row };
            }

            case 'text':
              return { value: cell, config: column?.config, row };

            case 'link':
            case 'htmlLink':
            case 'image':
            case 'badge':
            case 'icon':
            case 'actions':
            default:
              return { value: cell, config: column?.config, row };
          }
        });

        return { row, cells };
      });
    } catch (e) {
      console.error(e);
      this.displayableData = [];
    }
  }

  /**
   * Sort the rows and re-fetch the data.
   * TODO : check from pagination.sortableKeys
   * @param columnKey {string}
   */
  public sort(columnKey: string) {
    if (this.pagination.sortBy === columnKey && this.pagination.sortIn === 'ASC') {
      this.pagination.sortIn = 'DESC';
    } else if (this.pagination.sortBy === columnKey && this.pagination.sortIn === 'DESC') {
      this.pagination.sortIn = undefined;
    } else {
      this.pagination.sortIn = 'ASC';
    }

    this.pagination.sortBy = !this.pagination.sortIn ? undefined : columnKey;
    this.retrieveData();
  }

  public onChangePaginationLimit(value: string) {
    const offset = 0;
    this.pagination = { ...this.pagination, limit: parseInt(value), offset };

    this.retrieveData();
  }

  /**
   * Navigate to a specific page in the dataset and fetch the data
   *
   * @param page {number}
   */
  public goto(page: number) {
    this.pagination.offset = (page - 1) * this.pagination.limit;
    this.pagination.currentPage = page;

    this.retrieveData();
  }

  /**
   * This is used to know if a link should use <a [href]=""> or <a [routerLink]="" />
   *
   * @param link {string}
   */
  public isInternalLink(link?: string) {
    if (!link) return false;
    return link.startsWith('/');
  }

  public toggleAllSelected(allSelected: boolean) {
    this.allSelected = allSelected;
    this.selectedRows = [];
  }
  public stopPropagation(event: Event) {
    event.stopPropagation();
  }
  public isRowSelected(row: Row) {
    const firstRowKey = String(Object.keys(row)[0]);
    const idKey = this.idKey || firstRowKey;

    return this.selectedRows.findIndex((selected) => selected[idKey] === row[idKey]);
  }

  public toggleSelectedRow(row: Row) {
    this.allSelected = false;
    const alreadySelected = this.isRowSelected(row);

    if (alreadySelected < 0) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(alreadySelected, 1);
    }
  }

  public isFunction(value: unknown): value is Function {
    const isFunction = typeof value === 'function';
    return isFunction;
  }

  public activeDetails: number | null = null;

  public get detailsColumnsConfig(): DetailsRowConfig | null {
    const conf = this.displayableColumns.find((conf) => conf.type === 'details');

    if (conf) {
      return conf as DetailsRowConfig;
    }

    return null;
  }

  public toggleDetails(index: number): void {
    if (this.activeDetails === index) {
      this.activeDetails = null;
    } else {
      this.activeDetails = index;
    }
  }
}
